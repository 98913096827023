import React from 'react';

import {Container} from 'react-bootstrap'

const UnauthorizedComponent = () =>
  <Container className="container-fluid">
    Non sei autorizzato ad accedere a questa pagina.
  </Container>;

UnauthorizedComponent.propTypes = {};

UnauthorizedComponent.defaultProps = {};

export default UnauthorizedComponent;