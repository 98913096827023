import React, { useState, useContext } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { Form, Button, Alert } from "react-bootstrap";
import { AuthContext } from "../../authentication";
import { HOME } from "../../navigation/routes";

const LoginForm = ({location}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isAuth, login } = useContext(AuthContext);
  const [isLogged, setLogged] = useState(isAuth);
  const [error, setError] = useState();
  const {from} = location.state || {from: {pathname: HOME}}
  
  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setError();
      await login(username, password);
      setLogged(true);
    } catch (er) {
      setLogged(false);
      setError({ ...er, messaggio: "Accesso non eseguito"});
    }
  }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  return isLogged ? (
    <Redirect to={from}  />
  ) : (
    <div>
      <Form className="container" onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            onChange={e => setUsername(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        {error && (
          <Alert variant="danger">
            <Alert.Heading>{error.messaggio}</Alert.Heading>
            {error.response.data+"."}
          </Alert>
        )}
        <Button variant="primary" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
};

export default withRouter(LoginForm);
