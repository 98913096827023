import React, { useContext } from "react";

import HeaderComponent from "./HeaderComponent";
import { AuthContext } from "../../authentication";

const HeaderContainer = () => {
  const { isAuth, logout } = useContext(AuthContext);
  const { username, progetti } = useContext(AuthContext);
  return (
    <HeaderComponent 
      isAuth={isAuth} 
      username={username} 
      progetto={isAuth ? progetti[0].projectName : ""} 
      doLogout={logout} />
  );
};

HeaderContainer.propTypes = {};

HeaderContainer.defaultProps = {};

export default HeaderContainer;
