import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";

import HomeComponent from "./HomeComponent";
import { UNAUTHORIZED } from "../../navigation/routes";

import api from "../../api";

const merge = (activities, domains) =>
  activities.map(activity => {
    activity.descDomain = domains[activity.iddomain];
    return activity;
  });

const HomeContainer = ({ match }) => {
  const id = match.params.idpaziente;
  const [isLoaded, setIsLoaded] = useState({});
  const [domains, setDomains] = useState({});

  useEffect(() => {
    api.getData2(
      () => api.getActivities(id),
      () => api.getDomains(),
      setIsLoaded,
      setDomains
    );
    // api.getData(() => api.getDomains(), setDomains)
  }, [id]);

  if (!isLoaded.data || !domains.data)
    return (
      <div className="container" style={{ textAlign: "center", margin: 100 }}>
        <b>Caricamento...</b>
      </div>
    );

  const isValid = true;

  return isValid ? (
    <HomeComponent
      data={merge(isLoaded.data.activities, domains.data)}
      domains={domains.data}
      idpaziente={isLoaded.data.idPaziente}
      id={id}
    />
  ) : (
    <Redirect to={UNAUTHORIZED} />
  );
};

export default withRouter(HomeContainer);
