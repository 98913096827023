import React, { useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import api from "../../api";
import moment from "moment";

const headerStyle = { fontWeight: "bold", textAlign: "left" };

const Home = ({ data, domains, idpaziente, id }) => {
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(0);
  const [printing, setPrinting] = useState(false);

  const tableRef = useRef(null);

  const print = async () => {
    setPrinting(true);
    const res = await api.getPDFurl(idpaziente, selected);

    if (!res.data) {
      console.log(res);
    } else {
      window.open(res.data, "_blank");
    }

    setPrinting(false);
  };

  const toggleRow = id => {
    const newSelected = [...selected];

    if (newSelected.includes(id)) {
      let index = newSelected.indexOf(id);
      if (index !== -1) newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }

    setSelected(newSelected);

    if (newSelected.length === 0) {
      setSelectAll(0);
    } else if (newSelected.length === data.length) {
      setSelectAll(1);
    } else {
      setSelectAll(2);
    }
  };

  const toggleSelectAll = () => {
    let newSelected = [];

    if (selectAll === 0) {
      // newSelected = [...tableRef.current.state.sortedData.idactivity]
      tableRef.current.state.sortedData.forEach(x => {
        newSelected.push(x._original.idactivity);
      });
    }
    setSelected(newSelected);
    if (tableRef.current.state.sortedData.length === data.length) {
      setSelectAll(selectAll === 0 ? 1 : 0);
    } else {
      setSelectAll(selectAll === 0 ? 2 : 0);
    }
  };

  const defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    const value = String(filter.value);
    return row[id] !== undefined
      ? String(row[id])
          .toLowerCase()
          .startsWith(value.toLowerCase())
      : true;
  };

  const domainFilter = ({ filter, onChange }) => (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : "all"}
    >
      <option value="all" />
      {Object.keys(domains).map((key, index) => (
        <option key={index} value={domains[key]}>
          {domains[key]}
        </option>
      ))}
    </select>
  );

  const columns = [
    {
      id: "checkbox",
      accessor: "",
      filterable: false,
      Cell: ({ original }) => {
        return (
          <input
            type="checkbox"
            checked={selected.includes(original.idactivity)}
            onChange={() => toggleRow(original.idactivity)}
          />
        );
      },
      Header: x => {
        return (
          <input
            type="checkbox"
            checked={selectAll === 1}
            ref={input => {
              if (input) {
                input.indeterminate = selectAll === 2;
              }
            }}
            onChange={() => toggleSelectAll()}
          />
        );
      },
      width: 40
    },
    {
      Header: "Attività",
      accessor: "activityName"
    },
    {
      Header: "Dominio",
      accessor: "descDomain",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.descDomain === filter.value;
      },
      Filter: domainFilter
    },
    {
      Header: "Data",
      id: "activityDate",
      accessor: d => {
        let date = new Date(d.activityDate);
        return moment(date).format("DD-MM-YYYY HH:mm");
      },
      filterable: false
    },
    {
      Header: "Punteggio",
      accessor: "activityScore",
      filterable: false
    }
  ];

  return (
    <Container className="container-fluid">
      <Row style={{ paddingBottom: 10 }}>
        <Col>
          <h5>ID Paziente: {id}</h5>
        </Col>
        <Col md={"auto"}>
          <Button
            onClick={print}
            variant="success"
            size="sm"
            disabled={Object.keys(selected).length === 0 || printing}
          >
            {printing ? "Attendere " : "Stampa"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ReactTable
            columns={columns.map(col => ({ ...col, headerStyle }))}
            data={data}
            sortable={true}
            showPagination={true}
            defaultPageSize={10}
            noDataText={"No activities found."}
            filterable
            defaultFilterMethod={defaultFilterMethod}
            ref={tableRef}
          ></ReactTable>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
