import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "./authentication";
import AppRoutes from "./navigation/AppRoutes";
import HeaderContainer from "./components/Header/HeaderContainer";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <>
            <HeaderContainer />
            <AppRoutes />
          </>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
