import axios from "axios";

const apiConfig = {
  // development: "http://localhost:8080/",
  development: "https://reteneuro-rest.cbim.it/",
  production: "https://reteneuro-rest.cbim.it/"
};

const env = process.env.NODE_ENV || "development";
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL });

const authenticate = (email, password) => {
  const url = "api/login";
  return instance.post(url, null, { auth: { username: email, password } });
};

const getActivities = id => {
  const url = "api/activities/" + id;
  return instance.get(url, null);
};
const getDomains = () => {
  const url = "api/domains";
  // console.log(instance.get(url, null));
  return instance.get(url, null);
};

const getPDFurl = (id, activities) => {
  const url = "api/generatePDF/" + id;
  return instance.post(url, { activities });
};

const getData = (api, setState) => {
  const load = async () => {
    try {
      const res = await api();
      setState(res);
    } catch (err) {
      console.error(err);
      setState(err);
    }
  };
  load();
};

const getData2 = (api1, api2, setState1, setState2) => {
  const load = async () => {
    try {
      const res1 = await api1();
      setState1(res1);
      const res2 = await api2();
      setState2(res2);
    } catch (err) {
      console.error(err);
      setState1(err);
    }
  };
  load();
};

const setAuth = token =>
  (instance.defaults.headers.common["Authorization"] = "BEARER " + token);

const removeAuth = () => {
  instance.defaults.headers.common["Authorization"] = undefined;
  delete instance.defaults.headers.common["Authorization"];
};

export default {
  authenticate,
  getActivities,
  getDomains,
  getPDFurl,
  getData,
  getData2,
  setAuth,
  removeAuth
};
