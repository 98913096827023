import React, { Component } from 'react';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = { isAuth: false, superUser: false, username: '', role: '', progetti:[] }; //impostare isAuth e superUser a false !! IMPORTANTE !!

class AuthProvider extends Component {
  state = INITIAL_STATE;

  login = async (username, password) => {
    const { data } = await api.authenticate(username, password);
    this.setState({ 
      isAuth: true,
      username: data.username, 
      superUser: data.flagSuperUser === 1 ? true : false,
      role: data.role,
      progetti: data.listaProgetti
    });
    api.setAuth(data.jwt);
  }
  
  logout = () => {
    this.setState(INITIAL_STATE);
    api.removeAuth();
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, login: this.login, logout: this.logout}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }