import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from './routes';
import AuthRoute from './AuthRoute';

import LoginForm from '../components/Login/LoginForm';
import Home from '../components/Home/HomeContainer';
import Unauthorized from '../Unauthorized';

import { ALL} from '../constants';

export const AppRoutes = () =>{


  return(
    <Switch>
      <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
      <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized />} />
      <AuthRoute exact path={`${routes.HOME}/:idpaziente`} claim={[ALL]} component={() => <Home />} />
    </Switch>
  );
}

export default AppRoutes;
